import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';
import DROOLS from 'drools';
import DROOLS_pckg from '../../node_modules/drools/package.json';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";

const DROOLS_reduxPerInstance = DROOLS_pckg['app-framework'] && DROOLS_pckg['app-framework'].reduxPerInstance;

const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};

const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(!reduxPerInstance) return null;
  return (state, action) => {
    if(action.type.startsWith(`@${pckg}/`) || state === undefined) {
      if(action.instanceId) {
        if(Array.isArray(action.instanceId) && action.instanceId.includes(instanceId)) {
          return reducer(state, action);
        } else if (action.instanceId === instanceId) {
          return reducer(state, action);
        } else {
          return state;
        }
      } else {
        return reducer(state, action);
      }
    } else {
      return state;
    }
  };
};

const singleReducersInit = {
  'drools': singleReducer(DROOLS_reduxPerInstance, DROOLS.reducer),
};

const userReducersPerInstanceInit = {
  'drools': DROOLS.reducer && DROOLS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '1495c20f_ac2d_40f1_a56c_461b25db5da0': perInstanceReducer('drools', '1495c20f-ac2d-40f1-a56c-461b25db5da0', DROOLS_pckg['app-framework'], DROOLS.reducer),
  }).filter(pair => pair[1]))),
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});
